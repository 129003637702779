import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import MetroFares from "../components/metro-fares"

class CC2ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="CC2"
          description="View route information and buy your ticket for the CC2 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">CC2 service</h1>
              <p>York Road at Inglewood Drive - Corpus Christi</p>
              <p>Corpus Christi – York Road at Hawkhill Avenue</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="CC2 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1ZYaTQOCgcmHImNRDB7Nkzk8FWY8g6AzV"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Corpus Christi Catholic College
                </p>
                <p>
                  From Scholes Coronation Tree via Main Street, Station Road,
                  Scholes Lane, Westbound A64 York Road, Ring Road/ York Road
                  roundabout (360 degree turn), Eastbound A64 York Road (towards
                  York), Baildon Drive, Sherburn Road North, White Laithe Road,
                  Coal Road, Red Hall Lane, Wetherby Road, Ring Road, Monkswood
                  Gate, Monkswood Avenue, Kentmere Avenue, Boggart Hill Drive,
                  Easterly Road, Harehills Lane to Harehills Lane/ York Road –
                  then non-stop to school.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="CC2 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1LmOfftB6BElavVkrC7A3WPjuUR16tkg&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Corpus Christi Catholic College
                </p>
                <p>
                  Non-Stop to Harehills Lane/York Road then all stops to
                  Harehills Lane, Harehills Avenue, Roundhay Road, Easterly
                  Road, Boggart Hill Drive, Kentmere Avenue, Monkswood Avenue,
                  Monkswood Gate, Ring Road, Wetherby Road, Red Hall Lane, Coal
                  Road, White Laithe Road, Sherburn Road North, Baildon Drive,
                  Eastbound A64 York Road, Scholes Lane, Station Road, Main
                  Street, Scholes Coronation Tree.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Scholes Coronation Tree</td>
                      <td>DEP</td>
                      <td>0715</td>
                    </tr>
                    <tr>
                      <td>Baildon Drive</td>
                      <td>DEP</td>
                      <td>0725</td>
                    </tr>
                    <tr>
                      <td>Wetherby Road at Red Hall Gdns</td>
                      <td>DEP</td>
                      <td>0735</td>
                    </tr>
                    <tr>
                      <td>Easterly Road at Dib Lane</td>
                      <td>DEP</td>
                      <td>0745</td>
                    </tr>
                    <tr>
                      <td>Roundhay Road</td>
                      <td>DEP</td>
                      <td>0755</td>
                    </tr>
                    <tr>
                      <td>Harehills Lane/ Compton Road</td>
                      <td>DEP</td>
                      <td>0800</td>
                    </tr>
                    <tr>
                      <td>Corpus Christi</td>
                      <td>ARR</td>
                      <td>0820</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Corpus Christi CC</td>
                      <td>DEP</td>
                      <td>1510</td>
                    </tr>
                    <tr>
                      <td>Harehills Lane/ Compton Road</td>
                      <td>DEP</td>
                      <td>1525</td>
                    </tr>
                    <tr>
                      <td>Roundhay Road</td>
                      <td>DEP</td>
                      <td>1530</td>
                    </tr>
                    <tr>
                      <td>Easterly Road at North Lane</td>
                      <td>DEP</td>
                      <td>1540</td>
                    </tr>
                    <tr>
                      <td>Wetherby Road at Red Hall Gdns</td>
                      <td>DEP</td>
                      <td>1550</td>
                    </tr>
                    <tr>
                      <td>Baildon Drive</td>
                      <td>DEP</td>
                      <td>1600</td>
                    </tr>
                    <tr>
                      <td>Scholes Coronation Tree</td>
                      <td>ARR</td>
                      <td>1605</td>
                    </tr>
                  </tbody>
                </table>
                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default CC2ServicePage
